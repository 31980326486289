import React, {Component} from 'react';
import { BrowserRouter as Router, Route} from "react-router-dom";
import './bootstrap.min.css';
import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home.js";
import About from "./pages/About/About.js";
import Products from "./pages/Products/Products.js";
import Contact from "./pages/Contact/Contact.js";
import Nutrition from "./pages/Nutrition/Nutrition.js";
import Sustainability from "./pages/Sustainability/Sustainability.js";
import GivingBack from "./pages/GivingBack/GivingBack.js";

class App extends Component {
  render() {
    return (
      <Router>
        <div id="secondRoot">
          <Navbar/>
          <div className="main">
            <Route exact path = "/" component={Home}/>
            <Route exact path = "/about" component={About}/>
            <Route exact path = "/products" component={Products}/>
            <Route exact path = "/contact" component={Contact}/>
            <Route exact path = "/nutrition" component={Nutrition}/>
            <Route exact path = "/sustainability" component = {Sustainability}/>
            <Route exact path = "/givingback" component={GivingBack}/>
            <Route exact path = "/donate" component={GivingBack}/>
            </div>
            <Footer/>
        </div>
      </Router>
    );
  }
}

export default App;

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import "./Home.css"
import Nutrition from "../Nutrition/Nutrition.js";
import Shuffle from "../../components/Shuffle/Shuffle.js"
import Billboard from "../../components/Billboard.js";
import Sustainability from "../Sustainability/Sustainability.js";

class Home extends Component {
    render() {
        const Features = () => (
            <div className="features">
                <div className="row" id="featuresWrapper">
                    <div className="col-lg-4 feature" >
                        <div className="col-md-4 iconBox" >
                            <img className="ficon" src="./images/icon_heart.png" alt="" />
                        </div>
                        <div className="featureDetails col-md-8">
                            <h3>Nutrition</h3>
                            <p>Learn more about the benefits of quinoa.</p>
                            <Link to="/nutrition">more details</Link>
                        </div>
                    </div>

                    <div className="col-lg-4 feature" >
                        <div className="col-md-4 iconBox" >
                            <img className="ficon" src="./images/icon_leaf.png" alt="" />
                        </div>

                        <div className="featureDetails col-md-8">
                            <h3>Sustainability</h3>
                            <p>We are committed to operating in an environmentally-responsible way. Learn more about our initiatives.</p>
                            <Link to="/sustainability">more details</Link>
                        </div>
                    </div>

                    <div className="col-lg-4 feature" >
                        <div className="col-md-4 iconBox">
                            <img className="ficon" src="./images/icon_earth.png" alt="" />
                        </div>
                        <div className="featureDetails col-md-8">
                            <h3>Giving Back</h3>
                            <p>We pledge to donate 5% of our net profits to environmental and social non-profit organizations.</p>
                            <Link to="/givingback">more details</Link>
                        </div>
                    </div>
                </div>
            </div>
        );

        const Introduction = () => (
            <div className="row introduction ">
                <div className="col-lg-6" id="introductionImage"></div>
                <div className="col-lg-6" id="introductionDetails">
                    <h3>Organic Puffed Quinoa</h3>
                    <p>All the benefits of quinoa in a ready-to-eat form.
                    <br />No cooking necessary - just add milk and enjoy.</p>
                    <Link to="/products">more details</Link>
                </div>

            </div>
        );

        return (
            <div>
                <Billboard 
                backgroundImage = "/images/IMG_5529.jpg"
                backgroundStyling = "backgroundImg img-fluid"
                text = "introducing a new way to eat quinoa" />
                <Features />
                <Introduction />
                <Shuffle 
                testimonialHeader1 = "Organic Ingredients"
                testimonialText1 = "We use only organic ingredients in our products."
                testimonialHeader2 = "No Sugar Added"
                testimonialText2 = "Our Original Unsweetened Puffed Quinoa has not sugars added making it a versatile snack. Enjoy it by itself or with your favorite sweetener."/>
            </div>
        );
    }
}



export default Home;
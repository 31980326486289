import React, { Component } from "react";
import Slider from "react-slick";
import "./slick-theme.min.css";
import "./slick.min.css";

class Shuffle extends React.Component {
    render() {
        const settings = {
          dots: true,
          fade: true,
          infinite: true,
          autoplay: true,
          autoplaySpeed:3000,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          cssEase: "linear",
          centerMode: true,
          pauseOnHover: true,
          arrows: false
        };

        return (
            <div className="testimonials">
                <Slider {...settings}>
                    <div className="testimonial">
                        <h3>{this.props.testimonialHeader1}</h3>
                        <p>{this.props.testimonialText1}</p>
                    </div>

                    <div className="testimonial">
                        <h3>{this.props.testimonialHeader2}</h3>
                        <p>{this.props.testimonialText2}</p>
                    </div>   
                </Slider>
            </div>
        );
    }
}
             
export default Shuffle;

import React from "react";
import Billboard from "../../components/Billboard.js";
import ContactDetails from "../../components/ContactDetails.js"
import "./Contact.css"

const Contact = () => {
    return (
        <div>
            <Billboard
                backgroundImage="/images/contact.jpg"
                backgroundStyling="backgroundImg img-fluid"
                text="contact us"
            />
            <div className="contactText">
                <ContactDetails
                 title="Product Feedback"
                 description="We want to hear from you! To provide feedback about our products, please email us at"
                 email="contact@intiprovisions.com"
                 />
                 <span className="separatorLine"></span>
                 <br/>
                  <ContactDetails
                 title="General Inquiries"
                 description="For general inquiries, please email us at"
                 email="info@intiprovisions.com"
                 />
                 <span className="separatorLine"></span>
                 <br/>
                  <ContactDetails
                 title="Wholesale and Distribution"
                 description="For wholesale and distribution, please email us at"
                 email="sales@intiprovisions.com"
                 />
            </div> 
        </div>
    );
}

 
export default Contact;
import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer className= "footer text-muted text-center">
            <img className="footerIcon" src="./images/llama.png" alt="Inti Provisions footer icon"/>
            <p id="footerText">Copyright © Inti Provisions - All rights reserved</p>
        </footer>
    );
}

export default Footer;
import React from "react";
const Billboard = (props) => {
    return (
    <div>
        <div className={props.backgroundStyling} style={{backgroundImage: `url(${props.backgroundImage})`}}>
            <div className="wrapper">
                <p className="homeBanner">{props.text}</p>
            </div>
        </div>
    </div>
);
}
    

export default Billboard;
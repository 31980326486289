import React from "react";
import "./About.css";

const About = () => {
    return (
        <div>
            <div className="row aboutPageDetails">
                <div className="col-md-4"><img className="aboutPageImg" src="/images/austin_townlake.jpg " alt="" /></div>
                <div className="col-md-8 aboutPageText">
                    <h3>Our Story</h3>
                        <p>
                        Inti Provisions was started by Ron and Katya, who first met in college in Anchorage, Alaska. Here is their story:
                        <br/>
                        While in college, we instantly connected over our similar ideas and views. We both cherish the time spent with family and friends and, at the same time, enjoy travelling abroad and discovering new places and cultures. Our love for adventure took us to many corners of the world and eventually lead us to Austin, Texas which is now our home. We love its laidback lifestyle, friendly people, and how easy it is to stay active and spend time outdoors.
                        </p>
                        <p>
                        Ron was born in Peru, and it was one of the first countries we visited together. During our many visits to Peru, we would always enjoy the vast variety of quinoa products found there, like cereals, bars, and snacks. We simply could not get enough and would always bring some back with us. There was nothing like that readily available in the U.S. As we looked for healthier, better-tasting alternatives to what was available locally, an idea was born - we set out on a journey to bring quinoa-based foods to Austin. As a result, we created Inti Provisions, a company that reflects our values of preserving the environment, being socially responsible, and giving back to the community.
                        <br/>
                        <br/>
                        <em>Ron and Katya</em>
                    </p>
                </div>
            </div>
            <span className="aboutSeparatorLine"></span>

            <div className="row aboutPageDetails">
                <div className="col-md-8 aboutPageText">
                    <h3>Company</h3>
                    <p>
                    We named our company Inti Provisions to recognize our connection to the Andes where quinoa is cultivated and where the idea for our company began. In Quechua, one of the native languages of the Andes, “Inti” means Sun, who is the main deity in the Inca mythology.
                    </p>
                    <p>
                    We have a very specific vision for our company. At Inti Provisions, our goal is to bring to the market foods that are simple, healthy, and nutritious while minimizing our impact on the environment and giving back to the community. Taking care of the environment is extremely important to us – we are firm believers that the choices we make today will affect the quality of our lives in the future. That is why we use only organic ingredients and our packaging is made with 100% wind-generated electrical power. Our standup pouches are fully recyclable where PE film recycling facilities exist (e.g. grocery and retail store drop-off).
                    </p>
                    <p>
                    With so many options to choose from, it is up to us to lead a sustainable lifestyle: from the foods we choose to nourish our bodies, to the way we dispose of things that we no longer need.
                    </p>
                    <p>
                    Thank you for choosing Inti Provisions. We hope that you enjoy our products as much as we do! 
                    </p>
                </div>
                <div className="col-md-4"><img className="aboutPageImg" src="images/package_front_background.jpg" alt="Inti Package Front" /></div>
            </div>
            <span className="aboutSeparatorLine"></span>

            <div className="row aboutPageDetails">
                <div className="col-md-4"><img className="aboutPageImg" src="images/IMG_5068.jpg" alt="" /></div>
                <div className="col-md-8 aboutPageText">
                    <h3>Mission</h3>
                    <p>
                    Our mission is to make nutritious foods accessible to everyone while practicing sustainability and environmental awareness.
                    </p>
                </div>
            </div>
            <span className="aboutSeparatorLine"></span>

            <div className="row aboutPageDetails">
                <div className="col-md-8 aboutPageText">
                    <p>
                       <h3>Values</h3> 
                    </p>
                    
                    <p>
                        Quality
                        <br/>
                        Sustainability
                        <br/>
                        Social and Environmental Responsibility
                        <br/>
                        Transparency
                    </p>
                   
                </div>
                <div className="col-md-4"><img className="aboutPageImg" src="/images/IMG_5676.jpg" alt="Austin Townlake" /></div>
            </div>
        </div>
    );
}

export default About;
import React from "react";
import Billboard from "../../components/Billboard.js";
import "./Products.css"

const Products = () => {
    return (
        <div>
            <Billboard
                backgroundImage="/images/homepage.jpg"
                backgroundStyling="backgroundImg img-fluid"
                text="organic puffed quinoa"
            />
            <div className="productsText">
                <p>Quinoa is an excellent source of protein, fiber, and minerals. The airy texture of this cereal is achieved by increasing the internal pressure of the seeds causing them to expand. When puffed, it develops a pleasant toasted taste. It does not require cooking making it a versatile breakfast supplement, letting you enjoy all of the benefits that quinoa has to offer in a ready-to-eat form.
                <br/>
                <br/>
                Easy Ways to Eat Puffed Quinoa:
                <br/>
                <br/>
                <b>On its own</b>
                - it can be enjoyed straight from the bag.
                <br/>
                <b>As a cereal</b>
                - just add milk and sweetener of your choice.
                <br/>
                <b>Mix it in</b>
                - add puffed quinoa to your favorite granola for a more nutritious meal.
                <br/>
                <b>As a topper</b>
                - add it your yogurt, ice cream, or even a fruit salad.
                <br/>
                <b>Bake with it</b>
                – experiment by adding puffed quinoa to your cookie and muffin batter.
                <br/>
                <b>Make energy balls</b>
                - make your own energy packed snacks by mixing puffed quinoa with date paste and other ingredients of your choice.
                <br/>
                <br/>
                Our product is now available on Amazon! <a href="https://www.amazon.com/dp/B07K63DNDL?ref=myi_title_dp" target="_blank" rel= "noopener noreferrer" >Click here</a> and get yours today.
                <br/>
                <br/>
                Enjoy!
                </p>
            </div> 
        </div>
    );
}

export default Products;


 
import React from "react";
import Billboard from "../../components/Billboard.js";
import "./Nutrition.css"

const Nutrition = () => {
    return (
        <div>
            <Billboard
                backgroundImage="/images/IMG_5582.jpg"
                backgroundStyling="backgroundImg img-fluid"
                text="nutrition"
            />
            <div className="nutritionText">
                <h3 id="nutritionHeader">Organic Puffed Quinoa</h3>
                <p>Quinoa, essentially a seed and not a cereal grain as many have come to believe, belongs to the <em>Chenopodiaceae</em> family, which also includes spinach and beet. Quinoa contains more protein and fewer carbs than wheat, rye, rice, or oats. 
                Most importantly, it is a complete protein, meaning that it contains all nine essential amino acids including lysin. It has high levels of calcium, magnesium, iron, copper, and zinc. Rich in micronutrients, quinoa is reported to have high concentration of vitamins B6 (pyridoxine) and B9 (folate). B vitamins are known to aid with metabolism and converting food into energy. Quinoa is an ideal breakfast food full of protein, fiber, and other nutrients to give you energy and keep you full longer.
                <br/>For those who prefer to have their breakfast on the go, puffed quinoa is an excellent alternative. It can be added to smoothies and shakes, consumed as a cereal with a sweetener of your choice, added to granola, fruits, and nuts, or used in baking recipes. 
                <br/><br/>We hope that you enjoy our puffed quinoa as much as we do!</p>
            </div> 
        </div>
    );
}


export default Nutrition;

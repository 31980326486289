import React from "react";
import Billboard from "../../components/Billboard.js";
import "./Sustainability.css"
import Shuffle from "../../components/Shuffle/Shuffle.js"

const Recycle = () =>{
    return(
        <div id="recycleContainer">
            <div>
                <img id="recycleImage" src="/images/recycleIcon.png" alt="recycle icon"/>
            </div>
            <h4>Please recycle!</h4>
            <p>
            By recycling your Inti Provisions stand-up pouches, you are helping convert used packaging into new products such as park benches while diverting waste from the land-fill.
            </p>
        </div>

    );
}

const Sustainability = () => {
    return (
        <div>
            <Billboard
                backgroundImage="/images/renewableEnergy.jpg"
                backgroundStyling="backgroundImg img-fluid"
                text="sustainability"
            />
            <Shuffle 
                testimonialHeader1 = "Renewable energy"
                testimonialText1 = "Our packaging is manufactured in a factory designed and built to LEED sustainable building guidelines on equipment powered by 100% wind-generated electrical energy."
                testimonialHeader2 = "Recyclable packaging"
                testimonialText2 = "Our stand-up pouches are fully recyclable where PE film recycling facilities are available (e.g. grocery store drop-off). Please clean and dry the pouches before dropping them off."/>
            <Recycle/>
        </div>
    );
}

export default Sustainability;
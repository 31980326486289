import React from "react";
import Billboard from "../../components/Billboard.js";
import "./GivingBack.css";

const GivingBack = () => {
    return (
        <div >
            <Billboard
                backgroundImage="/images/quinoaFields.jpg"
                backgroundStyling="backgroundImg img-fluid"
                text="giving back"
            />
            <div id ="givingBackText">
                <p>
                Supporting the environment and our community is very important to us, that is why we pledge to donate 5% of our net profits to environmental and social non-profit organizations.

                We will select three or four different organization to donate to every year. As we select different non-profit organizations, we will list them here to bring awareness to their cause. 

                We are currently working on launching our product and we will update this section once we become profitable.
                </p>
            </div>
        </div>
    );
}

export default GivingBack;
import React from "react";
const ContactDetails = (props) => {
    return (
        <div>
            <p><b>{props.title}</b></p>
            <p>{props.description}</p>
            <p>{props.email}</p>
        </div>

    );
}


export default ContactDetails;